import logo from "../Images/Logo.png";
import React from "react";
import {Link} from "react-router-dom";

export const Footer = () => {
  return (
    <>
      {/*Footer Start*/}
      <div
        className="container-fluid bg-dark text-light mt-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ zIndex: 10, position: 'relative' }}
      >
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-4 col-md-6 footer-about">
              <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                <a href="/" className="navbar-brand">
                  <h1 className="m-0 text-white">
                    <img src={logo} style={{ height: "80px" }} alt="Logo" />
                    zeeventures
                  </h1>
                </a>
                <p className="mt-3 mb-4">
                  Your Ultimate partner for any innovative digital solution
                </p>
                {/*<form action="">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-white p-3"
                      placeholder="Your Email"
                    />
                    <button className="btn btn-dark">Sign Up</button>
                  </div>
                </form>*/}
              </div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row gx-5">
                <div className="col-lg-6 col-md-12 pt-5 mb-5"
                
                >
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Get In Touch</h3>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-geo-alt text-primary me-2"></i>
                    <p className="mb-0">Hikkaduwa, SriLanka</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-envelope-open text-primary me-2"></i>
                    <p className="mb-0">info@zeeventures.lk</p>
                  </div>
                  <div className="d-flex mb-2">
                    <i className="bi bi-telephone text-primary me-2"></i>
                    <p className="mb-0">+94 77 242 7603</p>
                  </div>
                  <div className="d-flex mt-4">
                    <a className="btn btn-primary btn-square me-2" href="https://www.facebook.com/profile.php?id=61560930316049&mibextid=ZbWKwL" target="_blank"
                     onMouseEnter={e => {
                      e.currentTarget.style.backgroundColor = '#9202ec';
                      e.currentTarget.style.color = '#FFFFFF';
                      e.target.style.borderColor = '#9202ec';
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.backgroundColor = ''; // Reset to default background color
                      e.currentTarget.style.color = ''; // Reset to default text color
                    }}
                  
                    >
                      <i className="fab fa-facebook-f fw-normal"></i>
                    </a>
                    <a className="btn btn-primary btn-square me-2" href="https://www.linkedin.com/company/zeeventures/" target="_blank"
                     onMouseEnter={e => {
                      e.currentTarget.style.backgroundColor = '#9202ec';
                      e.currentTarget.style.color = '#FFFFFF';
                      e.target.style.borderColor = '#9202ec';
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.backgroundColor = ''; // Reset to default background color
                      e.currentTarget.style.color = ''; // Reset to default text color
                    }}
                  
                    >
                      <i className="fab fa-linkedin-in fw-normal"></i>
                    </a>
                    <a className="btn btn-primary btn-square" href="https://www.instagram.com/zeeventures_lk?igsh=ZXI1bGJtYm5xbXhr" target="_blank"
                     onMouseEnter={e => {
                      e.currentTarget.style.backgroundColor = '#9202ec';
                      e.currentTarget.style.color = '#FFFFFF';
                      e.target.style.borderColor = '#9202ec';
                    }}
                    onMouseLeave={e => {
                      e.currentTarget.style.backgroundColor = ''; // Reset to default background color
                      e.currentTarget.style.color = ''; // Reset to default text color
                    }}
                  
                    >
                      <i className="fab fa-instagram fw-normal"></i>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="text-light mb-0">Quick Links</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <Link to={'/'}>
                      <a className="text-light mb-2" href="/">
                        <i className="bi bi-arrow-right text-primary me-2"></i>
                        Home
                      </a>
                    </Link>

                    <Link to={"/service"}>
                      <a className="text-light mb-2" >
                        <i className="bi bi-arrow-right text-primary me-2"></i>Our
                        Services
                      </a>
                    </Link>

                    <Link to={"/about"}>
                      <a className="text-light mb-2" >
                        <i className="bi bi-arrow-right text-primary me-2"></i>
                        About Us
                      </a>
                    </Link>

                  

                    <Link to={"/member"}>
                      <a className="text-light mb-2" >
                        <i className="bi bi-arrow-right text-primary me-2"></i>
                        Meet The Team
                      </a>
                    </Link>

                    <Link to={"/contact"}>
                      <a className="text-light">
                        <i className="bi bi-arrow-right text-primary me-2"></i>
                        Contact Us
                      </a>
                    </Link>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
          className="container-fluid text-white"
        style={{ background: "#061429",  zIndex: 10, position: 'relative'  }}
      >
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-6">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "75px" }}
              >
                <p className="mb-0">
                  &copy;{" "}
                  <a className="text-white">
                     {new Date().getFullYear()}
                  </a>
                   {"  "}zeeventures PVT Ltd
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Footer End*/}
    </>
  );
};