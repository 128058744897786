import React from "react";


const Timeline = () => {
  const coursesLeft = [
    "Requirement Gathering",
    "Project Planning",
    "Development",
    "Deployment and Launch",
    "Ongoing Support"
  ];

  const coursesRight = [
    "Research and Analysis",
    "Design",
    "Testing and Quality Assurance",
    "Post-Launch Support",
    "Maintenance Support"
  ];

  return (
    <div className="timeline-container">
      <h2 className="timeline-title">Development Process</h2>
      <div className="timeline-line"></div>
      <div className="flex flex-col">
        {coursesLeft.map((course, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-item-left">
              <span className="timeline-item-content">{course}</span>
            </div>
            <div className="timeline-item-right">
              <span className="timeline-item-content">{coursesRight[index] || ''}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
