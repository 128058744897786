import React, {useEffect, useState} from 'react';
import Particles, {initParticlesEngine} from "@tsparticles/react";
import {loadFull} from "tsparticles";
import particlesOptions from "../particles.json";


export const Hero = () => {
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init) {
            return;
        }

        initParticlesEngine(async (engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    return (
        <div>
            <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" style={{position: 'relative'}}>
                        <img className="w-100" src="img/robot-working-office-instead-humans.jpg" alt="Image"/>
                        {init && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 10
                            }}>
                                <Particles options={particlesOptions}/>
                            </div>
                        )}
                        <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                            <div className="p-3" style={{maxWidth: '900px'}}>
                                <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                                    {/* Creative & Innovative */}
                                </h5>
                                <h1 className="display-1 text-white mb-md-4 animated zoomIn">
                                    Creative & Innovative Digital Solution
                                </h1>
                                <a href="quote.html" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">
                                    Free Quote
                                </a>
                                <a href="contact" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

