import React, { useState } from 'react';

const BlogItem = ({ imgSrc, category, date, title, content }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
            <div className="blog-item bg-light rounded overflow-hidden">
                <div className="blog-img position-relative overflow-hidden">
                    <img
                        className="img-fluid rounded-top"
                        src={imgSrc}
                        alt=""
                        style={{ 
                            borderRadius: '15px 15px 0 0', 
                            width: '100%',
                            height: '200px', 
                            objectFit: 'cover' 
                        }}
                    />
                    <a className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4">
                        {category}
                    </a>
                </div>
                <div className="p-4">
                    <h4 className="mb-3">{title}</h4>
                    <p>{isExpanded ? content : `${content.substring(0, 50)}...`}</p>
                    <button className="text-uppercase border-0" style={{ background: 'none', color: '#a442ad' }} onClick={toggleReadMore}>
                        {isExpanded ? 'Read Less' : 'Read More'}
                    </button>
                 
                </div>
            </div>
        </div>
    );
};

export const Product = () => {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [visiblePosts, setVisiblePosts] = useState(6);

    const blogPosts = [
        {
            imgSrc: 'img/mobileApp.jpg',
            category: 'Our Products',
            date: '11 May, 2024',
            title: 'Mobile App',
            content: 'Get ready to ride the HelpMe into a new era with the HelpMe App, set to release in January 2025. HelpMe The app promises to revolutionize the way you interact, share, and stay connected with friends and the world around you'
        },
        {
            imgSrc: 'img/onlineCourses.png',
            category: 'Online Courses',
            date: '11 May, 2024',
            title: 'Programming Courses',
            content: 'Master web, mobile, and standalone app development. Dive into blockchain and version control. Learn web hosting essentials. Unlock your full potential with our premium online courses.'
        },

        {
            imgSrc: 'img/flashProject.jpg',
            category: 'Projects Zone',
            date: '11 May, 2024',
            title: 'Flash Projects',
            content: 'Dive into real-world experience with web, mobile, and standalone app projects designed for university students. Perfect for building your portfolio and gaining practical skills. Grab your project now and start coding!'
        },
        // Add more blog posts here as needed
    ];

    const filteredBlogPosts = selectedCategory === 'All'
        ? blogPosts
        : blogPosts.filter(post => post.category === selectedCategory);

    const visibleBlogPosts = filteredBlogPosts.slice(0, visiblePosts);

    const handleSeeMore = () => {
        setVisiblePosts(prev => prev + 6);
    };

    return (
        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" style={{ zIndex: 10, position: 'relative' }}>
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                    <h5 className="fw-bold text-primary text-uppercase">Our Contribution</h5>
                    <h1 className="mb-0">Read our latest Contribution</h1>
                </div>
                <div className="row g-5 ">
                    {visibleBlogPosts.map((post, index) => (
                        <BlogItem key={index} {...post} />
                        
                    ))}
                 
                </div>
             
                <div className="text-center mt-4">
                    {visiblePosts < filteredBlogPosts.length && (
                        <button className="btn btn-primary mx-2" onClick={handleSeeMore}>See More</button>
                    )}
                    {visiblePosts > 6 && (
                        <button className="btn btn-secondary mx-2" onClick={() => setVisiblePosts(6)}>See Less</button>
                    )}
                </div>
            </div>
        </div>
    );
};
