export const ServiceSub = () => {
    return (
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" style={{ zIndex: 10, position: 'relative' }}>
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
            <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
            <h1 className="mb-0">Empowering Your Business with Tailored IT Solutions</h1>
          </div>
          <div className="row g-5">
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa fa-code text-white"></i>
                </div>
                <h4 className="mb-3">Web App Development</h4>
                <p className="m-0">Building responsive and scalable websites to enhance your online presence.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fab fa-android text-white"></i>
                </div>
                <h4 className="mb-3">Mobile App Development</h4>
                <p className="m-0">Creating user-friendly mobile apps for Android and iOS platforms.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa fa-chart-pie text-white"></i>
                </div>
                <h4 className="mb-3">Inventory System</h4>
                <p className="m-0">Control stock, reduce expenses, increase earnings. Data-driven insights.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa fa-search text-white"></i>
                </div>
                <h4 className="mb-3">SEO Optimization</h4>
                <p className="m-0">Improving your website's visibility on search engines to attract more visitors.</p>
              </div>
            </div>
         
            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                <i className="fa-solid fa-pen-ruler text-white"></i>

                </div>
                <h4 className="mb-3">Graphics Design</h4>
                <p className="m-0">Craft captivating visuals, iconic logos, and seamless user experiences.</p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
              <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                <div className="service-icon">
                  <i className="fa-brands fa-uncharted text-white"></i>
                </div>
                <h4 className="mb-3">Social Media Marketing</h4>
                <p className="m-0">Grow your brand, engage audience, boost sales online.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  };
  