import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

export const Vendor = () => {
    const settings = {
        // dots: true,
        infinite: true,
        speed: 5000,
        slidesToShow: 9, // Adjust the number of slides to show
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="container carousel-container"
        style={{ zIndex: 10, position: 'relative' }}
        >
            <Slider {...settings}>
                <div>
                    <img src="img/react.png" alt="React"/>

                </div>
                <div>
                    <img src="img/android.png" alt="Android"/>

                </div>
                <div>
                    <img src="img/aws.png" alt="AWS"/>

                </div>
                <div>
                    <img src="img/firebase.png" alt="Firebase"/>

                </div>
                <div>
                    <img src="img/flutter.png" alt="Flutter"/>

                </div>
                <div>
                    <img src="img/java.png" alt="Java"/>

                </div>
                <div>
                    <img src="img/mongo.png" alt="MongoDB"/>

                </div>
                <div>
                    <img src="img/mysql.png" alt="MySQL"/>

                </div>
                <div>
                    <img src="img/node-js.png" alt="Node.js"/>

                </div>
                <div>
                    <img src="img/nuxtjs.png" alt="Nuxt.js"/>

                </div>
                <div>
                    <img src="img/python.png" alt="Python"/>

                </div>
                <div>
                    <img src="img/swift.png" alt="swift"/>

                </div>
            </Slider>
        </div>
    );
};
