import emailjs from "emailjs-com";
import { useState } from "react";

export const Quote = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
        .send(
            "service_xn1emjj", // Replace with your EmailJS service ID
            "template_ytjqqgp", // Replace with your EmailJS template ID
            formData,
            "LKmIZMGZYy5m3v-MT" // Replace with your EmailJS user ID
        )
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Message sent successfully!");
        })
        .catch((err) => {
          console.error("FAILED...", err);
          alert("Failed to send message. Please try again later.");
        });

    // Clear form fields after submission
    setFormData({
      name: "",
      email: "",
      service: "",
      message: "",
    });
  };

  return (
      <>
        <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s"
      
        >
          <div className="container py-5" >
            <div className="row g-5">
              <div className="col-lg-7">
              <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '800px' }}>
                  <h5 className="fw-bold text-primary text-uppercase">
                    Request A Quote
                  </h5>
                  <h1 className="mb-0">
                    Need A Free Quote? Please Feel Free to Contact Us
                  </h1>
                </div>
                <div className="row gx-3"     style={{ zIndex: 10, position: 'relative' }}>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-4">
                      <i className="fa fa-reply text-primary me-3"></i>Reply
                      within 24 hours
                    </h5>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-4">
                      <i className="fa fa-phone-alt text-primary me-3"></i>24
                      hrs telephone support
                    </h5>
                  </div>
                </div>
                <p className="mb-4"     style={{ zIndex: 10, position: 'relative' }}>
                  Elevate your business with innovative digital solutions designed
                  to transform and enhance your brand identity.
                </p>

                <div
                    className="d-flex align-items-center mt-2 wow zoomIn"
                    data-wow-delay="0.6s"
                    style={{ zIndex: 10, position: 'relative' }}
                >
                  <div
                      className="bg-primary d-flex align-items-center justify-content-center rounded"
                      style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white"></i>
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+94 772427603</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div
                    className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                    data-wow-delay="0.9s"

                >
                  <form onSubmit={handleSubmit}
                      style={{ zIndex: 13, position: 'relative' }}
                  >
                    <div className="row g-3">
                      <div className="col-xl-12">
                        <input
                            type="text"
                            className="form-control bg-light border-0"
                            placeholder="Your Name"
                            style={{height: "55px", borderRadius: "25px"}}
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                      </div>
                      <div className="col-12">
                        <input
                            type="email"
                            className="form-control bg-light border-0"
                            placeholder="Your Email"
                            style={{height: "55px", borderRadius: "25px"}}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            title="Please enter a valid email address"
                        />
                      </div>
                      <div className="col-12">
                        <select
                            className="form-select bg-light border-0"
                            style={{height: "55px", borderRadius: "25px"}}
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                        >
                          <option value="">Select A Service</option>
                          <option value="Web Development">Web Development</option>
                          <option value="Mobile App Development">Mobile App Development</option>
                          <option value="SEO Optimization">SEO Optimization</option>
                          <option value="POS System">POS System</option>
                          <option value="Data Analytics">Data Analytics</option>
                        </select>
                      </div>
                      <div className="col-12">
                    <textarea
                        className="form-control bg-light border-0"
                        rows="3"
                        placeholder="Message"
                        style={{borderRadius: "25px"}}
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                      </div>
                      <div className="col-12">
                        <button
                            className="btn btn-dark w-100 py-3"
                            type="submit"
                            style={{height: "55px", borderRadius: "25px"}}
                        >
                          Request A Quote
                        </button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};