import React, { useState , useEffect} from 'react';
import styled from 'styled-components';

const projects = [

    {
        title: 'Blood Donatin',
        description: 'Fully Compleated Blood Donation With Management System',
        frontend:"Frontend : React js | TailwindCSS",
        backend:"Backend :Nodejs | Express ",
        database:"Database : mongodb",
        imageUrls: ['img/zone/plu1.png', 'img/zone/plu2.png', 'img/zone/plu3.png', 'img/zone/plu4.png'],
        link: 'https://example.com/project1',
        category: 'web'
    },

    {
        title: 'Wall Art Store',
        description: 'Fully Compleated Wall Art Store With Ordering System',
        frontend:"Frontend : React js | TailwindCSS",
        backend:"Backend :Nodejs | Express ",
        database:"Database : mongodb",
        imageUrls: ['img/zone/wal1.png', 'img/zone/wal2.png', 'img/zone/wal3.png'],
        link: 'https://example.com/project1',
        category: 'web'
    },
    {
        title: 'CV Maker',
        description: 'Fully Compleated CV Maker App. filling details and you can after downloading your CV when clicking the button',
        frontend:"Frontend : ReactNative",
        backend:"Backend :  ",
        database:"Database : ",
        imageUrls: ['img/zone/ha1.jpeg', 'img/zone/ha2.jpeg', 'img/zone/ha3.jpeg', 'img/zone/ha4.jpeg', 'img/zone/ha5.jpeg', 'img/zone/ha6.jpeg'],
        link: 'https://example.com/project1',
        category: 'mobile'
    },

    {
        title: 'Booking App',
        description: 'Fully Compleated Booking App',
        frontend:"Frontend : ReactNative | GeminiAPI | Google Map API",
        backend:"Backend : Firebase ",
        database:"Database : Firebase",
        imageUrls: ['img/zone/nkApp1.png', 'img/zone/nkApp2.png', 'img/zone/nkApp3.png'],
        link: 'https://example.com/project1',
        category: 'mobile'
    },

    {
        title: 'Quiz App',
        description: 'Fully Compleated Quiz App',
        frontend:"Frontend : ReactNative | TailwindCSS",
        backend:"Backend :  no",
        database:"Database : no",
        imageUrls: ['img/zone/q1.png', 'img/zone/q2.png', 'img/zone/q3.png','img/zone/q4.png'],
        link: 'https://example.com/project1',
        category: 'mobile'
    },


    {
        title: 'Job Finder',
        description: 'Fully Compleated Job Finder App',
        frontend:"Frontend : ReactNative | Custom Css",
        backend:"Backend :  no",
        database:"Database : no",
        imageUrls: ['img/zone/a1.jpeg', 'img/zone/a2.jpeg', 'img/zone/a3.jpeg','img/zone/a4.jpeg' ,'img/zone/a5.jpeg' ,'img/zone/a6.jpeg','img/zone/a7.jpeg'],
        link: 'https://example.com/project1',
        category: 'mobile'
    },
    {
        title: 'Doorlock Shop',
        description: 'Fully Compleated Doorlock Shop System 2 Table Transactions are There',
        frontend:"Frontend : React js",
        backend:"Backend : SpringBoot ",
        database:"Database : mySQL",
        imageUrls: ['img/zone/nkD1.jpeg', 'img/zone/nkD2.jpeg', 'img/zone/nkD3.jpeg','img/zone/nkD4.jpeg'],
        link: 'https://example.com/project1',
        category: 'web'
    },
    {
        title: 'Car Rental',
        description: 'Fully Compleated Car Rental System 2 Table Transactions are There',
        frontend:"Frontend : html css js",
        backend:"Backend : ajax REST API Spring",
        database:"Database : mySQL",
        imageUrls: ['img/zone/nkEasy1.jpeg', 'img/zone/nkEasy2.jpeg', 'img/zone/nkEasy3.jpeg','img/zone/nkEasy4.jpeg'],
        link: 'https://example.com/project1',
        category: 'web'
    },

    {
        title: 'Instrument Shop',
        description: 'Fully Compleated Instrument Shop management System 2 Table Transactions are There',
        frontend:"Frontend : React js",
        backend:"Backend :Nodejs | Express ",
        database:"Database : mongodb",
        imageUrls: ['img/zone/nkMu1.jpeg', 'img/zone/mkMu2.jpeg', 'img/zone/nkMu3.jpeg'],
        link: 'https://example.com/project1',
        category: 'web'
    },

  

];

const ProjectCardWrapper = styled.div`
    .project-card {
        border: 1px solid #ddd;
        border-radius: 8px;
        overflow: hidden;
        margin: 16px;
        width: 300px;
    }

    .project-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .project-content {
        padding: 16px;
    }

    .project-link {
        color: #b92eec;
        text-decoration: none;
        &:hover {
            color: white;
        }
    }
`;

const CarouselWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CarouselImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const CarouselButton = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 8px;
    cursor: pointer;
    &:hover {
        background: rgba(0, 0, 0, 0.8);
    }
`;
const ShoppingCartIcon = styled.i`
  margin-left: 10px;
  color: #b92eec;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    color: #a020f0;
  }
`;



const openWhatsApp = () => {
    const whatsappNumber = '+94772427603';
    const message = encodeURIComponent('Hello, I would like to know more about...');
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

    window.open(whatsappUrl, '_blank', 'width=600,height=600');
};

const handleClick = () => {
    openWhatsApp();
};




const Carousel = ({ images, interval = 3000 }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    useEffect(() => {
        const autoplay = setInterval(nextImage, interval);
        return () => clearInterval(autoplay);
    }, [currentIndex, interval]);

    return (
        <CarouselWrapper>
            <CarouselButton onClick={prevImage} style={{ left: '10px' }}>{'<'}</CarouselButton>
            <CarouselImage src={images[currentIndex]} alt={`carousel-${currentIndex}`} />
            <CarouselButton onClick={nextImage} style={{ right: '10px' }}>{'>'}</CarouselButton>
        </CarouselWrapper>
    );
};

const truncateText = (text, limit) => {
    const words = text.split(' ');
    return words.length > limit ? words.slice(0, limit).join(' ') + '...' : text;
};

const ProjectCard = ({ title, description,imageUrls, onViewProject }) => {
    const truncatedDescription = truncateText(description, 3);

    return (
        <ProjectCardWrapper>
            <div className="project-card">
                <Carousel images={imageUrls} />
                <div className="project-content">
                    <h3>{title}</h3>
                
                    <p>{truncatedDescription}</p>
                    <button onClick={onViewProject} className="project-link">View Project</button>
                    <ShoppingCartIcon className="fa fa-shopping-cart" aria-hidden="true"  onClick={handleClick}/>
                </div>
            </div>
        </ProjectCardWrapper>
    );
};

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    width: 100%;
    overflow: auto;
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
`;

const Modal = ({ project, onClose }) => {
    if (!project) return null;
    return (
        <ModalOverlay onClick={onClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <h2>{project.title}</h2>
                {project.imageUrls.map((url, index) => (
                    <ModalImage key={index} src={url} alt={`project-${index}`} />
                ))}
                <h3 className='pt-4'>{project.description}</h3>
                <h4>{project.frontend}</h4>
                <h4>{project.backend}</h4>
                <h4>{project.database}</h4>
             
                {/*<a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">View Project</a>
                <button onClick={onClose}>Close</button>*/}
            </ModalContent>
        </ModalOverlay>
    );
};

const FilterButton = styled.button`
  background: ${({ active }) => (active ? '#b92eec' : '#ddd')};
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  &:hover {
    background: #b92eec;
  }
`;

export const Project = () => {
    const [filter, setFilter] = useState('all');
    const [selectedProject, setSelectedProject] = useState(null);

    const filteredProjects = filter === 'all' ? projects : projects.filter(project => project.category === filter);

    const handleViewProject = (project) => {
        setSelectedProject(project);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
    };

    return (
        <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-1">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                        <h1 className="mb-0">Flash Projects</h1>
                    </div>
                    <div className="text-center">
                        <FilterButton active={filter === 'all'} onClick={() => setFilter('all')}>All</FilterButton>
                        <FilterButton active={filter === 'web'} onClick={() => setFilter('web')}>Web</FilterButton>
                        <FilterButton active={filter === 'mobile'} onClick={() => setFilter('mobile')}>Mobile</FilterButton>
                        <FilterButton active={filter === 'pos'} onClick={() => setFilter('pos')}>POS</FilterButton>
                    </div>
                    <div className="projects-list">
                        {filteredProjects.map((project, index) => (
                            <ProjectCard
                                key={index}
                                title={project.title}
                                description={project.description}
                                imageUrls={project.imageUrls}
                                link={project.link}
                                onViewProject={() => handleViewProject(project)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Modal project={selectedProject} onClose={handleCloseModal} />
        </div>
    );
};