import React from 'react';

function DigitalMarketing() {
  return (
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-7">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '800px' }}>
              <h5 className="fw-bold text-primary text-uppercase"></h5>
              <h1 className="mb-0">
                Transforming Businesses with Fresh Talent in Dynamic Digital Marketing
              </h1>
            </div>
            <p className="mb-4">
              We are a newly established business with a team of talented and dynamic digital marketing professionals. Our goal is to provide innovative IT solutions that empower businesses to thrive in a digital world. Leveraging our collective experience and fresh perspectives, we deliver award-winning solutions tailored to your specific needs.
            </p>
            <div className="row g-0 mb-3">
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3"></i>Professional Staff
                </h5>
              </div>
              <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3"></i>24/7 Support
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary me-3"></i>Fair Prices
                </h5>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
              <div
                className="bg-primary d-flex align-items-center justify-content-center rounded"
                style={{ width: '60px', height: '60px' }}
              >
                <i className="fa fa-phone-alt text-white"></i>
              </div>
              <div className="ps-4">
                <h5 className="mb-2">Call to ask any question</h5>
                <h4 className="text-primary mb-0">+94 772427603</h4>
              </div>
            </div>
            <a href="Quote" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">
              Request A Quote
            </a>
          </div>
          <div className="col-lg-5" style={{ minHeight: '500px' }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100 rounded wow zoomIn"
                data-wow-delay="0.9s"
                src="img/digitalMarketing.png"
                style={{ objectFit: 'cover' }}
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalMarketing;
