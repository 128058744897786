import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const visionContent = [
  {
    title: 'Vision',
    description: 'To be the premier provider of innovative digital solutions, transforming industries and enriching lives globally.'
  },
  {
    title: 'Mission',
    description: 'At zeeventures, we are dedicated to leading the digital revolution, delivering cutting-edge services that drive growth, inspire our team, and uplift society.'
  }
];

const Vision = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  return (
    <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s" style={{ zIndex: 10, position: 'relative' }}>
      <div className="container py-5">
        <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
          <h5 className="fw-bold text-primary text-uppercase">Our Vision and Mission</h5>
          <h1 className="mb-0">Transforming Vision into Reality</h1>
          <p className="text-lg font-medium text-gray-700">
            At zeeventures, we are dedicated to transforming your vision into reality through innovative and tailored solutions. Our expert team is committed to excellence, from the initial consultation to project completion and beyond.
          </p>
        </div>
        <div className="overflow-hidden">
          <Slider {...settings}>
            {visionContent.map((item, index) => (
              <div key={index} className="rounded overflow-hidden p-6 bg-white">
                <h2 className="text-2xl font-bold text-center mb-4">{item.title}</h2>
                <p className="text-lg font-medium text-gray-700 text-center">
                  {item.description}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Vision;
