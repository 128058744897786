import React from 'react';


export const Pricing = () => {
  const openWhatsApp = () => {
    const whatsappNumber = '+94772427603';
    const message = encodeURIComponent('Hello, I would like to know more About Prices...');
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${message}`;

    window.open(whatsappUrl, '_blank', 'width=600,height=600');
  };

  const handleClick = () => {
    openWhatsApp();
  };

  const pricingPlans = [
    {
      title: "Basic Plan",
      description: "Consulting",
      price: 500,
      priceUnit: "1 hour",
      features: [
        "Initial Consultation",
        "Project Planning",
        "Strategy Development",
        "Follow-up Sessions",
       
      ],
      exclusions: ["Project Reporting"],
      bgColor: "bg-light",
      delay: "0.6s"
    },
    {
      title: "Standard Plan",
      description: "Incomplete Projects",
      price: 8000,
      priceUnit: "Starting Price",
      features: [
        "Project Assessment",
        "Issue Identification",
        "Execution and Monitoring",
        "Follow-up Sessions"
      ],
      exclusions: ["Project Reporting"],
      bgColor: "bg-white shadow position-relative",
      delay: "0.3s",
      zIndex: 1
    },
    {
      title: "Advanced Plan",
      description: "Ideal Projects",
      price: 20000,
      priceUnit: "Starting Price",
      features: [
        "Comprehensive Consultation",
        "Custom Project Planning",
        "Full Development Cycle",
        "Follow-up Sessions",
        "Project Reporting"
      ],
      bgColor: "bg-light",
      delay: "0.9s"
    }
  ];

  return (
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s" style={{ zIndex: 10, position: 'relative' }}>
        <div className="container py-5">
          <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: "600px" }}>
            <h5 className="fw-bold text-primary text-uppercase">Pricing Plans</h5>
            <h1 className="mb-0">We are Offering Competitive Prices for University Students</h1>
          </div>
          <div className="row g-4">
            {pricingPlans.map((plan, index) => (
                <div key={index} className={`col-lg-4 wow slideInUp`} data-wow-delay={plan.delay}>
                  <div className={`pricing-card ${plan.bgColor} rounded shadow-lg`} style={{ zIndex: plan.zIndex || 'initial' }}>
                    <div className="border-bottom py-4 px-5 mb-4 ">
                      <h4 className="text-primary mb-1">{plan.title}</h4>
                      <small className="text-uppercase">{plan.description}</small>
                    </div>
                    <div className="p-5 pt-0">
                      <h1 className="display-5 mb-3 pricing-amount">
                        <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>Rs</small>
                        {plan.price}
                        <small className="align-bottom" style={{ fontSize: "22px", lineHeight: "40px" }}>.00</small>
                        <small className="align-bottom" style={{ fontSize: "16px", lineHeight: "40px" }}>/ {plan.priceUnit}</small>
                      </h1>
                      {plan.features.map((feature, featureIndex) => (
                          <div key={featureIndex} className="d-flex justify-content-between mb-3">
                            <span>{feature}</span>
                            <i className="fa fa-check text-primary pt-1"></i>
                          </div>
                      ))}
                      {plan.exclusions && plan.exclusions.map((exclusion, exclusionIndex) => (
                          <div key={exclusionIndex} className="d-flex justify-content-between mb-2">
                            <span>{exclusion}</span>
                            <i className="fa fa-times text-danger pt-1"></i>
                          </div>
                      ))}
                      <button className="btn btn-gradient py-2 px-4 mt-4" onClick={handleClick}
                              style={{ width: '100%' }}>
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
}

