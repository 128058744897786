
import CountdownTimer from "../component/CountDown";

export const Cources = () => {

    const targetDate = "2024-10-02T00:00:00";
    return(

        <>

            <div className="app">
                <header style={{display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    backgroundColor: '#282c34',
                    textAlign: 'center'}}>
                    <h1 style={{color:'#a442ad',
                        }}>Countdown for our courses</h1>
                    <CountdownTimer targetDate={targetDate} />
                </header>
            </div>
        </>
    )
}