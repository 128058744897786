
import { Member } from "../component/Member";
import Process from "../component/Timeline";
import Vision from "../component/Vision";
import {Vendor} from "../component/Vendor";
import {Facts} from "../component/Facts";


export const About = () => {
  return (
    <>
   
      <div
        className="container-fluid bg-primary py-5 bg-header"
        style={{ marginBottom: "90px" }}
      >
        <div className="row py-5">
          <div className="col-12 pt-lg-5 mt-lg-5 text-center">
            <h1 className="display-4 text-white animated zoomIn">About Us</h1>
            <a href="" className="h5 text-white">
              Home
            </a>
            <i className="far fa-circle text-white px-2"></i>
            <a href="" className="h5 text-white">
              About
            </a>
          </div>
        </div>
      </div>
      <Facts />
      <Vision />
      <Process />
      <Vendor/>
      <Member />
   
    </>
  );
};
