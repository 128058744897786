import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

const teamMembers = [
    {
        img: 'img/dineth.png',
        name: 'Dineth Panditha',
        designation: 'Founder',
        socials: [
            { platform: 'facebook', icon: 'fab fa-facebook-f' },
            { platform: 'instagram', icon: 'fab fa-instagram' },
            { platform: 'linkedin', icon: 'fab fa-linkedin-in' },
        ],
    },
    {
        img: 'img/harshana.png',
        name: 'Harshana Senadeera',
        designation: 'CO Founder',
        socials: [
            { platform: 'facebook', icon: 'fab fa-facebook-f' },
            { platform: 'instagram', icon: 'fab fa-instagram' },
            { platform: 'linkedin', icon: 'fab fa-linkedin-in' },
        ],
    },
    {
        img: 'img/pasindu.png',
        name: 'Sathira Kushan',
        designation: 'Data Analytics',
        socials: [
            { platform: 'facebook', icon: 'fab fa-facebook-f' },
            { platform: 'instagram', icon: 'fab fa-instagram' },
            { platform: 'linkedin', icon: 'fab fa-linkedin-in' },
        ],
    },
    {
        img: 'img/nk.png',
        name: 'Nethmi Kumuduni',
        designation: 'Business Analyst',
        socials: [
            { platform: 'facebook', icon: 'fab fa-facebook-f' },
            { platform: 'instagram', icon: 'fab fa-instagram' },
            { platform: 'linkedin', icon: 'fab fa-linkedin-in' },
        ],
    },
    {
        img: 'img/madu.png',
        name: 'Nimesha Madhubashini',
        designation: 'Project Manager',
        socials: [
            { platform: 'facebook', icon: 'fab fa-facebook-f' },
            { platform: 'instagram', icon: 'fab fa-instagram' },
            { platform: 'linkedin', icon: 'fab fa-linkedin-in' },
        ],
    },
    {
        img: 'img/ayesh.png',
        name: 'Ayesh Bataduwaarachchi',
        designation: 'Fullstack developer',
        socials: [
            { platform: 'facebook', icon: 'fab fa-facebook-f' },
            { platform: 'instagram', icon: 'fab fa-instagram' },
            { platform: 'linkedin', icon: 'fab fa-linkedin-in' },
        ],
    },
    {
        img: 'img/shashikala.png',
        name: 'Shashikala Dilrukshi',
        designation: 'Quality Assurance',
        socials: [
            { platform: 'facebook', icon: 'fab fa-facebook-f' },
            { platform: 'instagram', icon: 'fab fa-instagram' },
            { platform: 'linkedin', icon: 'fab fa-linkedin-in' },
        ],
    },



    // Add other team members...
];

export const Member = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s" style={{ zIndex: 10, position: 'relative' }}>
            <div className="container py-5">
                <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '800px' }}>
                    <h5 className="fw-bold text-primary text-uppercase">Team Members</h5>
                    <h1 className="mb-0">Professional staff Ready to Help Your Business</h1>
                </div>
                <Slider {...settings}>
                    {teamMembers.map((member, index) => (
                        <div key={index} className="team-item rounded overflow-hidden">
                            <div className="team-img position-relative overflow-hidden">
                                <img className="img-fluid" src={member.img} alt={member.name} />
                            </div>
                            <div className="text-center py-4">
                                <h4 className="text-primary">{member.name}</h4>
                                <p className="text-uppercase m-0">{member.designation}</p>
                                <p className="text-lowercase m-0"
                                   style={{
                                       textAlign: 'center',
                                       fontStyle: 'italic',
                                       color: '#6c757d',
                                       marginTop: '5px'
                                   }}>{member.designation2}</p>

                                <div className="team-social mt-3">
                                    {member.socials.map((social, i) => (
                                        <a key={i} className="btn btn-lg  btn-lg-square rounded"
                                            /*onMouseEnter={e => e.currentTarget.style.color = 'white'}
                                            onMouseMove={e => e.currentTarget.style.backgroundColor = '#9202ec'}
                                            onMouseLeave={e => e.currentTarget.style.borderColor = '#9202ec'}*/>
                                            <i className={social.icon}></i>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};